<template>
  <div>

    <b-row class="match-height">
      <b-col
        v-for="raffle in raffles.filter(currentRaffle => { if (category != null) { return currentRaffle.categories[0].name === category} else { return true }})"
        :key="raffle.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <raffle-card :raffle="raffle" />
      </b-col>
    </b-row>

    <div class="mx-2 mb-2">
      <b-row v-if="pagination">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando {{ pagination.total_objects }} de {{ rafflesCount }} registros</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BPagination,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import RaffleCard from '@/@core/components/raffles/RaffleCard.vue'

export default {
  components: {
    BCol,
    BRow,
    RaffleCard,
    BPagination,
  },
  props: {
    category: {
      type: String,
      default: null,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('raffles', ['raffles', 'pagination', 'rafflesCount']),
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce.scss";
.btn-cart {
  border-radius: 0 0 0.358rem 0.358rem;
}
</style>
