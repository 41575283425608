<template>
  <div class="pt-1">
    <home-menu bgwhite />
    <b-container class="pt-5">
      <b-row>
        <b-col sm="6">
          <h3 class="mb-0 font-weight-bolder">
            SORTEOS
          </h3>
        </b-col>
        <b-col
          sm="6"
          class="d-flex align-items-beetwen justify-content-end"
        >
          <div>
            <b-button
              v-model="cat"
              size="sm"
              class="mx-25"
              :variant="cat === null ? 'info' : null"
              pill
              @click="handleFilterCat(null)"
            >
              Todo
            </b-button>
            <b-button
              v-for="category in nonRepeated(raffles.map(x => {
                if(x.categories.length > 0) {
                  return x.categories[0].name
                }
                return 'Sin categoría'
              }))"
              :key="category"
              v-model="cat"
              :variant="cat === category ? 'info' : null"
              size="sm"
              class="mx-25"
              pill
              @click="handleFilterCat(category)"
            >
              {{ category }}
            </b-button>
          </div>
          <div>
            <b-form-select
              v-model="raffleStatus"
              :options="options"
              size="sm"
              @change="value => filterRaffles(value)"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="my-2"
        >
          <b-form-input
            id="searchQuery"
            v-model="searchQuery"
            placeholder="Buscar Sorteo"
          />
        </b-col>
        <b-col cols="12">
          <raffles-paginated :category="cat" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  BFormInput,
} from 'bootstrap-vue'
import _ from 'lodash'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import HomeMenu from '@/views/home/HomeMenu.vue'
import RafflesPaginated from '@/@core/components/raffles/RafflesPaginated.vue'

export default {
  components: {
    HomeMenu,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    RafflesPaginated,
  },
  data() {
    return {
      searchQuery: null,
      filterBy: 0,
      raffleStatus: null,
      cat: null,
      selectedCategory: null,
      options: [
        { value: false, text: 'Activos' },
        { value: true, text: 'Finalizados' },
        { value: 'null', text: 'Todos' },
      ],
      categories: [],
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  computed: {
    ...mapGetters('raffles', ['raffles', 'rafflesCount']),
  },
  watch: {
    searchQuery: _.debounce(function searchQuery(query) {
      this.getClientRaffles({ by_name: query })
    }, 250),
  },
  mounted() {
    this.getClientRaffles({ })
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
    this.RESET_RAFFLES()
  },
  methods: {
    ...mapActions('raffles', ['getClientRaffles']),
    ...mapMutations('raffles', ['RESET_RAFFLES']),
    nonRepeated(array) {
      return _.uniq(array)
    },
    handleFilterCat(category) {
      this.cat = category
    },
    filterRaffles(value) {
      if (value === 'null') {
        this.getClientRaffles()
      } else {
        this.getClientRaffles({ by_has_winner: value })
      }
    },
  },

}
</script>
